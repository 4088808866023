<template>
	<div v-if="item">
		<v-container
			v-if="item.hero_image"
			fluid
			pa-0
		>
			<v-img
				:src="item.hero_image.url"
				:alt="item.hero_image.alt"
				height="200"
			/>
		</v-container>
		<v-container class="container--narrow pt-6">
			<p
				v-if="item.summary"
				class="font-weight-medium"
				v-html="item.summary"
			/>
			<ContentBlocks :items="item.content_blocks" />
		</v-container>
	</div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'Event',
	data: () => ({
		item: {},
	}),
	computed: {
		...mapState([
			'events',
		]),
	},
	mounted () {
		// Find current item from the store
		this.item = this.events.find(item => {
			return item.name == this.$route.params.pagename
		})

		if (!this.item) {
			this.$router.replace({ name: 'error404' })
		}
	},
}
</script>
