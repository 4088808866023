<i18n>
{
  "en": {
		"tabs": {
			"member": "For members",
			"public": "Public",
			"raidepuolue": "Raidepuolue"
		},
		"noItemsText": "No news to show."
	},
  "fi": {
		"tabs": {
			"member": "Jäsenille",
			"public": "Julkiset",
			"raidepuolue": "Raidepuolue"
		},
		"noItemsText": "Uutisia ei löytynyt."
	}
}
</i18n>

<template>
	<div>
		<!-- Output child page -->
		<router-view :key="$route.fullPath" />

		<!-- Output navigation if we're not on child page -->
		<template v-if="$route.name == 'newsContainer'">
			<v-toolbar
				:color="this.$vuetify.theme.dark ? null : 'primary'"
				dark
				flat
			>
				<v-tabs
					v-model="currentTab"
					centered
					center-active
					show-arrows
				>
					<v-tabs-slider />
					<v-tab
						v-for="(item, index) in tabs"
						:key="'tab-' + index"
					>
						{{ $i18n.t('tabs.' + item) }}
					</v-tab>
				</v-tabs>
			</v-toolbar>
			<v-tabs-items v-model="currentTab">
				<!-- News for members -->
				<v-tab-item>
					<v-container class="container--narrow">
						<FilterableList
							:items="memberNews"
							subtitle-src="date"
							:meta-src="null"
							redirect-url-src="link"
							:no-items-text="$i18n.t('noItemsText')"
							@itemClick="item => $router.push({ name: 'news', params: { pagename: item.name } })"
						>
							<template #title="slotProps">
								{{ slotProps.item.title }}

								<v-chip
									v-if="slotProps.item.badge"
									color="secondary"
									small
									class="ml-2"
								>
									{{ slotProps.item.badge }}
								</v-chip>
							</template>
						</FilterableList>
					</v-container>
				</v-tab-item>

				<!-- Public news -->
				<v-tab-item>
					<v-container class="container--narrow">
						<FilterableList
							:items="publicNews"
							subtitle-src="date"
							:meta-src="null"
							redirect-url-src="link"
							:no-items-text="$i18n.t('noItemsText')"
						/>
					</v-container>
				</v-tab-item>

				<!-- Raidepuolue news -->
				<v-tab-item>
					<v-container class="container--narrow">
						<FilterableList
							:items="raidepuolueNews"
							subtitle-src="date"
							:meta-src="null"
							redirect-url-src="link"
							:no-items-text="$i18n.t('noItemsText')"
						/>
					</v-container>
				</v-tab-item>
			</v-tabs-items>
		</template>
	</div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'NewsContainer',
	data: () => ({
		tabs: [
			'member',
			'public',
			'raidepuolue',
		],
		currentTab: 0,
	}),
	computed: {
		...mapState({
			memberNews: state => state.news.member,
			publicNews: state => state.news.public,
			raidepuolueNews: state => state.news.raidepuolue,
		}),
	},
	mounted () {
		this.$api.News.doRequest()
	},
}
</script>
